import {Card, CardActions, CardContent, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useUserContext} from "../../context/UserContext";
import {userService} from "../../services/user.service";
import Loader from "../Loader";
import {notificationService} from "../../services/notification.service";
import RedirectButton from "../../components/Mui/RedirectButton";
import {Stack} from "@mui/material";
import {hydraService} from "../../utils/hydra";
import snackBar from "../../components/SnackBar";
import {run} from "../../utils/services";
import Confirmation from "../../feature/Confirmation";

const Notifications = () => {
    const {currentUser: {id}, notificationUnread, setNotificationUnread} = useUserContext();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([]);
    useEffect(() => {
        if (!id) {
            return;
        }
        userService.getUserNotifications(id)
            .then((response) => {
                setData(hydraService.getMembers(response.responseData));
                setLoading(false);
                if (notificationUnread > 0) {
                    notificationService.markAsRead({userId: id, notificationId: null})
                        .then(() => {
                            setNotificationUnread(0);
                        });
                }
            });
    }, [id]);

    const handleDelete = (obj) => {
        const id = obj?.notification?.id;
        run(notificationService.removeNotification, {id})
            .then(() => {
                setData(prevState => prevState.filter(el => el?.notification?.id !== id));
                snackBar('La notification a été supprimée.', 'success');
            });
    }

    return loading ? <Loader/> :
        <>
            {data.length > 0 ? (
                    <Stack spacing={3}>
                        {data.map((el, i) => (
                            <Card key={i}>
                                <CardContent>
                                    <Typography gutterBottom={true} variant={'h1'} component="div">
                                        {`Pour le ticket ${el.notification.strawTicket.number} > ${el.notification.typeTranslation} :`}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {el.notification.message}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Stack direction={'row'} spacing={1}>
                                        <RedirectButton to={el.notification.strawTicket['@id']}
                                            // startIcon={<DeleteIcon />}
                                        >
                                            Voir
                                        </RedirectButton>
                                        <Confirmation handleConfirm={() => handleDelete(el)}
                                                      modalTitle={'Suppression de notification'}
                                                      modalContent={'Confirmez vous la suppression de la notification ?'}
                                                      variant={'text'}
                                                      startIcon={null}
                                        />
                                    </Stack>
                                </CardActions>
                            </Card>
                        ))}
                    </Stack>
                )
                : (
                    <div>Pas de notification</div>
                )
            }
        </>
}

export default Notifications;