import {Grid, MenuItem} from "@mui/material";
import Autocomplete from "../../../components/Formik/Autocomplete";
import TextField from "../../../components/Formik/TextField";
import Select from "../../../components/Formik/Select";
import Checkbox from "../../../components/Formik/Checkbox";
import DateNative from "../../../components/Formik/Date/Native";
import HumidityPage from "./HumidityPage";
import {useFormikContext} from "formik";
import {memberService} from "../../../services/member.service";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import NumberField from "../../../components/Formik/NumberField";
import {useCallback, useEffect, useMemo, useState} from "react";
import DisableTextField from "../../../components/Mui/DisableTextField";
import {hydraService} from "../../../utils/hydra";
import Loader from "../../Loader";
import SelectDriverTransport from "./SelectDriverTransport";
import {run} from "../../../utils/services";
import {contractService} from "../../../services/contract.service";
import {useUserContext} from "../../../context/UserContext";
import {ROLES} from "../../../constants/roles";
import {useStrawTicketContext} from "../../../context/StrawTicketContext";
import BallNumberField from "./Detail/BallNumberField";

const cache = {};

const DetailPage = ({id, catalog}) => {
    const {isGranted} = useUserContext();
    const {
        memberSelected,
        setMemberSelected,
        setContractSelected,
        contractSelected,
        setHasContractChange
    } = useStrawTicketContext();
    const {values, setFieldValue, setValues} = useFormikContext();
    const {humidities} = values;
    const [loading, setLoading] = useState(!!id);
    const [ready] = useState(false);

    useEffect(() => {
        if (values.member) {
            run(memberService.getMember, hydraService.getIdFromIri(values.member))
                .then((response) => {
                    const member = response.responseData;
                    setMemberSelected(member);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!values.contract || !values.article) {
            return;
        }

        if (!!id && contractSelected !== null && values.contract !== hydraService.getIriFromItem(contractSelected)) {
            alert("Attention l'année de contrat a été modifiée.\n\nMerci de vérifier la couleur chauffeur, puis de vous rendre dans l'onglet fabrication avant d'enregistrer afin que les nouvelles valeurs soient bien prises en compte.");
            setHasContractChange(true);
        }

        const contractId = hydraService.getIdFromIri(values.contract);
        if (contractId in cache) {
            setContractSelected(cache[contractId]);
            calculateDeliveryContract(contractId, values.grossWeight, values.tareWeight, values.isSoldOut);
        } else {
            run(contractService.getContract, contractId)
                .then((response) => {
                    cache[contractId] = response.responseData
                    setContractSelected(cache[contractId]);
                    calculateDeliveryContract(contractId, values.grossWeight, values.tareWeight, values.isSoldOut);
                });
        }
    }, [values.contract, values.article, values.grossWeight, values.tareWeight, values.isSoldOut]);

    const calculateDeliveredWeight = (grossWeight, tareWeight) => {
        const value = grossWeight && tareWeight ? Math.max(+grossWeight - +tareWeight, 0) : '';
        return Number(value).toFixed(2);
    }

    const deliveredWeightMemo = useMemo(() => {
        return calculateDeliveredWeight(values.grossWeight, values.tareWeight);
    }, [values.grossWeight, values.tareWeight]);


    const calculateDeliveryContract = (contractId, grossWeight, tareWeight, isSoldOut = false) => {
        const contractSelected = cache[contractId];
        const deliverySelected = contractSelected?.calculateStrawDelivered?.find(el => el.article['@id'] === values.article);
        const {toDeliver, delivered} = deliverySelected;
        const stillDelivered = (isSoldOut ? 0 : (+toDeliver - +delivered)).toFixed(2);

        setValues(prevValues => ({
            ...prevValues,
            'toDeliver': toDeliver,
            'delivered': delivered,
            'stillDelivered': stillDelivered
        }));
    }

    const calculateAverageHumidity = useMemo(() => {
        const initialAcc = {dividend: 0, divisor: 0};
        const {dividend, divisor} = humidities.reduce((acc, humidity) => ({
            dividend: acc.dividend + Number(humidity.value),
            divisor: acc.divisor + (Number(humidity.value) > 0 ? 1 : 0)
        }), initialAcc);
        if (divisor === 0) {
            return '';
        }
        return (dividend / divisor).toFixed(2);
    }, [humidities]);

    const resetContracts = useCallback((member) => {
        setValues(prevValues => {
            return ({
                ...prevValues,
                'member': hydraService.getIriFromItem(member),
                'contract': null,
                'toDeliver': null,
                'delivered': null,
                'stillDelivered': null,
                'article': null,
                'harvestYear': null
            })
        });
        setMemberSelected(member);
    }, []);

    useEffect(() => {
        if (!ready) {
            return;
        }
        const articleSelected = catalog.articles.find(el => el['@id'] === values.article);
        setFieldValue('fabrication.qualityType', articleSelected?.qualityType);
    }, [values.article]);

    return (
        loading ? <Loader/> :
            <>
                <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                    <Grid item xs={12}>
                        <AutocompleteAsync
                            name={'member'}
                            label={'Adhérent...'}
                            filterName={'company'}
                            request={memberService.getAllMembers}
                            getOptionLabel={(option) => `${option.company} ${option.code}`}
                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                            value={memberSelected}
                            callback={resetContracts}
                            hydra
                            nullIfEmpty
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            name={'number'}
                            label={'N° ticket'}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            name={'contract'}
                            label={'Contrat'}
                            disabled={(memberSelected?.contractInfos || []).length === 0 || isGranted(ROLES.MEMBER)}
                        >
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {(memberSelected?.contractInfos || []).map((el, i) => (
                                <MenuItem
                                    key={i}
                                    value={el.contract['@id']}>
                                    {`${el.year} - ${el?.contractType ?? ''} - ${el?.farmingType}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            name={'isSoldOut'}
                            label={'Soldé'}
                            disabled={!values.isLastTicket}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            name={'article'}
                            label={'Article'}
                            options={catalog.articles}
                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                            hydra
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NumberField
                            asString
                            name={'toDeliver'}
                            label={'A livrer'}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NumberField
                            asString
                            name={'delivered'}
                            label={'Livré'}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NumberField
                            asString
                            name={'stillDelivered'}
                            label={'Reste à livrer'}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DateNative
                            name={'loadingDate'}
                            label={'Date de chargement'}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            name={'deliveryPlace'}
                            label={'Lieu de livraison'}
                            nullIfEmpty
                        >
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {catalog.deliveryPlaces.map((el, i) => <MenuItem key={i} value={el}>{el}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid container item xs={12} md={4} justifyContent={'center'}>
                        {values.signature && <img src={values.signature}/>}
                    </Grid>

                    <SelectDriverTransport catalog={catalog}/>

                    <Grid item xs={12} md={6}>
                        <TextField
                            name={'weighingNumber'}
                            label={'N° pesée'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NumberField
                            asString
                            name={'grossWeight'}
                            label={'Poids brut'}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NumberField
                            asString
                            name={'tareWeight'}
                            label={'Tare'}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            name={'deliveredWeight'}
                            label={'Poids livré/net'}
                            value={deliveredWeightMemo}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <BallNumberField loading={loading} catalog={catalog}/>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{'paddingLeft': '0!important'}}>
                        <HumidityPage humidities={humidities}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DateNative name={'weighingDate'} label={'Date de pesée'} nullIfEmpty/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DisableTextField
                            label={'Moyenne humidité'}
                            value={calculateAverageHumidity}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            name={'driverComment'}
                            label={'Commentaire chauffeur'}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </>
    );
}

export default DetailPage;
