import {Form, Formik} from "formik";
import * as Yup from "yup";
import {Grid, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import DetailPage from "./DetailPage";
import FabricationPage from "./FabricationPage";
import {useEffect, useState} from "react";
import {siloService} from "../../../services/silo.service";
import {articleService} from "../../../services/article.service";
import {hydraService} from "../../../utils/hydra";
import Loader from "../../Loader";
import {useNavigate, useParams} from "react-router-dom";
import {ticketService} from "../../../services/ticket.service";
import {catalogService} from "../../../services/catalog.service";
import {driverService} from "../../../services/driver.service";
import snackBar from "../../../components/SnackBar";
import Range from "../../../utils/range";
import {run} from "../../../utils/services";
import {ARTICLE_TYPE_CODES} from "../../../constants/types";
import {ROLES} from "../../../constants/roles";
import {useUserContext} from "../../../context/UserContext";
import FormDisabler from "../../../components/Formik/ThemeFormikDisablerProvider";
import {StrawTicketContextProvider} from "../../../context/StrawTicketContext";
import NavigationButtons from "../NavigationButtons";
import {exportService} from "../../../services/export.service";
import _ from "lodash";
import ModificationComment from "../ModificationComment";
import {useDialog} from "../../../feature/DialogProvider";


const StrawPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const INITIAL_FORM_STATE = {
        number: '',
        contract: null,
        toDeliver: "",
        delivered: "",
        stillDelivered: "",
        isSoldOut: false,
        deliveryPlace: null,
        signature: "",
        driver: null,
        colorDriver: null,
        transportType: null,
        deliveryType: null,
        weighingNumber: "",
        grossWeight: "",
        tareWeight: "",
        deliveredWeight: "",
        ballNumber: null,
        humidities: Range(0, 14).map(() => ({value: ''})),
        averageHumidity: "",
        driverComment: "",
        member: null,
        fabrication: {
            fabricationDate: null,
            qualityType: null,
            manufacturedBall: null,
            productionLine: null,
            ballWeight: null,
            uniformity: null,
            pebble: null,
            humidity: null,
            colorManufacturing: null,
            pollution: null,
            presentation: null,
            loadingTime: null,
            averageColorimetry: null,
            pebbleWeight: null,
            humidityRate: null,
            comment: null,
            hasNoSupplement: null,
            isFabricationValidate: false,
            isFabricationVisible: false
        },
        weighingDate: null,
        loadingDate: null,
        harvestYear: null,
        article: null,
        blocked: false,
        strawTickets: [],
        canBeRemove: true,
        isLastTicket: false
    };
    const FORM_VALIDATION = Yup.object().shape({});
    const [loading, setLoading] = useState(!!id);
    const [catalog, setCatalog] = useState({
        silos: [],
        articles: [],
        deliveryPlaces: [],
        drivers: [],
        transports: [],
        deliveryTypes: [],
    });
    const [data, setData] = useState(INITIAL_FORM_STATE);
    const {isGranted} = useUserContext();
    const [openDialog] = useDialog();

    useEffect(() => {
        Promise.all([
            run(siloService.getAllSilos, {pagination: false}),
            run(articleService.getAllArticles, {pagination: false, 'type.code': ARTICLE_TYPE_CODES.PAILLE}),
            run(catalogService.getAllCatalogDeliveriesPlaces),
            run(driverService.getAllDrivers, {pagination: false}),
            run(catalogService.getAllCatalogTransportsTypes),
            run(catalogService.getAllCatalogDeliveriesTypes),
            run(catalogService.getAllQualityType),
        ]).then((values) => {
            let [
                silos, articles, deliveryPlaces, drivers, transports, deliveryTypes, qualityTypes
            ] = values.map(el => el.responseData);
            silos = hydraService.getMembers(silos);
            articles = hydraService.getMembers(articles);
            drivers = hydraService.getMembers(drivers);
            setCatalog({
                ...catalog,
                silos,
                articles,
                deliveryPlaces,
                drivers,
                transports,
                deliveryTypes,
                qualityTypes
            });

            if (!!id) {
                run(ticketService.getStrawTicket, id)
                    .then((response) => {
                        setData({
                            ...response.responseData,
                            toDeliver: null,
                            delivered: null,
                            stillDelivered: null
                        });
                        setLoading(false);
                    });
            }
        });
        return () => null;
    }, [id]);

    // TabContext
    const [value, setValue] = useState('0');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const isFabricationVisible = Boolean(data.fabrication.isFabricationVisible);
    return (
        loading ? <Loader/> :
            <>
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={data}
                    enableReinitialize
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, {setFieldError, setFieldValue, setSubmitting, setValues}) => {
                        if (id && !_.isEqual(data, values)) {
                            openDialog({
                                children: <ModificationComment request={ticketService.editStrawTicket}
                                                               data={values}
                                                               setData={setData}
                                                               setFieldError={setFieldError}
                                />
                            })
                            return;
                        }

                        if (!id) {
                            run(ticketService.addStrawTicket, values, {setFieldError, setSubmitting})
                                .then((response) => {
                                    snackBar('Enregistrement effectué');
                                    setFieldValue('number', response.responseData?.number);
                                    navigate(response.responseData['@id']);
                                });
                        } else {
                            run(ticketService.editStrawTicket, {id, ...values}, {setFieldError, setSubmitting})
                                .then((response) => {
                                    setData({...values, canBeRemove: response.responseData.canBeRemove});
                                    snackBar('Enregistrement effectué');
                                });

                        }
                    }}
                >
                    <Form>
                        <StrawTicketContextProvider>
                            <Grid container spacing={3} className="Grid Grid-root">
                                <Grid item xs={6} className="Grid">
                                </Grid>
                                <Grid container item xs={6} justifyContent={'flex-end'} className="Grid">
                                    <NavigationButtons putRequest={ticketService.editStrawTicket}
                                                       exportRequest={exportService.exportStrawTicket}
                                                       removeRequest={ticketService.removeStrawTicket}/>
                                </Grid>
                            </Grid>
                            <TabContext value={value}>
                                <TabList value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Détails" value={'0'}/>
                                    {isFabricationVisible && (
                                        <Tab label="Fabrication" value={'1'} disabled={!id}/>
                                    )}
                                </TabList>
                                <TabPanel value={'0'}>
                                    <FormDisabler disabled={isGranted(ROLES.MEMBER)}>
                                        <DetailPage id={id} catalog={catalog}/>
                                    </FormDisabler>
                                </TabPanel>
                                {isFabricationVisible && (
                                    <TabPanel value={'1'}>
                                        <FormDisabler disabled={isGranted(ROLES.MEMBER)}>
                                            <FabricationPage name={'fabrication'}
                                                             catalogArticle={catalog.articles}
                                                             catalogQualityTypes={catalog.qualityTypes}
                                            />
                                        </FormDisabler>
                                    </TabPanel>
                                )}
                            </TabContext>
                        </StrawTicketContextProvider>
                    </Form>
                </Formik>
            </>
    )
}

export default StrawPage;
