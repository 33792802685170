import {useFormikContext} from "formik";
import {Grid, MenuItem} from "@mui/material";
import Select from "../../../components/Formik/Select";
import Radio from "../../../components/Formik/Radio";
import {useEffect, useState} from "react";
import {run} from "../../../utils/services";
import {strawService} from "../../../services/straw.service";
import {hydraService} from "../../../utils/hydra";
import {ROLES} from "../../../constants/roles";
import {useUserContext} from "../../../context/UserContext";
import {TRANSPORT_TYPE} from "../../../constants/types";
import {useStrawTicketContext} from "../../../context/StrawTicketContext";

let cache = {};
let cacheOrder = [];

const SelectDriverTransport = ({catalog}) => {
    const {isGranted} = useUserContext();
    const {contractSelected, hasContractChange} = useStrawTicketContext();
    const {values, setFieldValue} = useFormikContext();
    const {driver, transportType} = values;
    const {drivers} = catalog;
    const [catalogColorDrivers, setCatalogColorDrivers] = useState(null);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setReady(true);
    }, []);

    useEffect(() => {
        if (!ready || values.hasInvoiceTransport === true) {
            return;
        }
        const idDriver = drivers.find(id => id['@id'] === driver)

        if (idDriver && (idDriver.memberTransport === true) && (transportType !== TRANSPORT_TYPE.MEMBER)) {
            setFieldValue('transportType', TRANSPORT_TYPE.MEMBER)
            setFieldValue('deliveryType', 'Standard')
        } else if (idDriver && (idDriver.memberTransport !== true) && (transportType !== TRANSPORT_TYPE.TRUCK)) {
            setFieldValue('transportType', TRANSPORT_TYPE.TRUCK)
            setFieldValue('deliveryType', null)
        }
    }, [driver]);

    const filterByArticle = (array, articleId = null) => {
        if (!articleId) {
            return array;
        }
        return array.filter(el => hydraService.getIriFromItem(el.article) === articleId);
    }

    const reduceCache = () => {
        cacheOrder = cacheOrder.slice(-5)
        const sliced = cacheOrder.reduce((acc, value) => {
            acc[value] = cache[value];
            return acc;
        }, {});
        return sliced;
    }

    /**
     * Get color drivers, and filter by article.
     */
    useEffect(() => {
        if (!contractSelected && !values.harvestYear) {
            return;
        }

        const year = contractSelected ? contractSelected.year : values.harvestYear;

        if (hasContractChange) {
            setFieldValue('colorDriver', null); // quand le catalogue de couleur est rechargé / modifié, la couleur sélectionnée ne peut pas y être.
        }
        if (year in cache) {
            setCatalogColorDrivers(filterByArticle(cache[year], values.article));
            return;
        }
        run(strawService.getAllStrawColorDrivers, {pagination: false, year: year})
            .then((response) => {
                cacheOrder.push(year);
                cache[year] = hydraService.getMembers(response.responseData);
                cache = reduceCache();
                setCatalogColorDrivers(filterByArticle(cache[year], values.article));
            });
    }, [contractSelected, values.article]);

    return (
        <>
            <Grid item xs={12} md={6}>
                <Select name={'driver'} label={'Chauffeur'}>
                    <MenuItem value={''}><em>Aucun</em></MenuItem>
                    {drivers.map((el, i) => <MenuItem key={i} value={el['@id']}>{el.name}</MenuItem>)}
                </Select>
            </Grid>
            <Grid item xs={12} md={6}>
                {catalogColorDrivers !== null &&
                    <Select name={'colorDriver'}
                            label={'Couleur chauffeur'}
                            disabled={catalogColorDrivers.length === 0 || isGranted(ROLES.MEMBER)}
                            nullIfEmpty
                    >
                        <MenuItem value={''}><em>Aucun</em></MenuItem>
                        {catalogColorDrivers.map((el, i) => <MenuItem key={i} value={el['@id']}>{el.label}</MenuItem>)}
                    </Select>}
            </Grid>
            <Grid item xs={12} md={6}>
                <Select name={'transportType'}
                        label={'Type de transport'}
                        disabled={!!values.hasInvoiceTransport}
                >
                    <MenuItem value={''}><em>Aucun</em></MenuItem>
                    {catalog.transports.sort().map((el, i) => {
                        return <MenuItem key={i} value={el}>{el}</MenuItem>
                    })}
                </Select>
            </Grid>
            <Grid item xs={12} md={6}>
                {transportType === "Adhérent" &&
                    <Radio
                        name={'deliveryType'}
                        options={catalog.deliveryTypes.map(el => ({value: el, label: el}))}
                        disabled={!!values.hasInvoiceTransport}
                        row
                    />
                }
            </Grid>
        </>
    )
}

export default SelectDriverTransport