import NumberField from "../../../../components/Formik/NumberField";
import {useFormikContext} from "formik";
import {useEffect, useState} from "react";
import {useStrawTicketContext} from "../../../../context/StrawTicketContext";

const BallNumberField = ({loading, catalog = {}}) => {
    const {values, setValues} = useFormikContext();
    const {calculateBallWeight} = useStrawTicketContext()
    const [ready, setReady] = useState(false);

    useEffect(() => {

        if (loading) {
            return;
        }

        if (!ready) { // Hack reloading
            setReady(true);
            return;
        }

        const timeoutId = setTimeout(async() => {
            const ballWeight = await calculateBallWeight(catalog, values, values.ballNumber);
            setValues(prevValues => ({
                ...prevValues,
                fabrication: {
                    ...prevValues.fabrication,
                    manufacturedBall: +values.ballNumber,
                    ballWeight: ballWeight
                }
            }));
        }, 600);
        return () => {
            clearTimeout(timeoutId);
        }
    }, [
        values.ballNumber, catalog
    ]);

    return (
        <NumberField
            label={'Nombre de balles livrées'}
            name={'ballNumber'}
            nullIfEmpty
        />
    );
}

export default BallNumberField;